<template>
  <div>
    <div class="bg-mask"></div>
    <div class="bg-circles"></div>
    <div class="relative m-auto mx-4 mt-16 z-10">
      <div class="back-btn-wrapper">
        <button class="back-btn" @click="()=> this.$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
            <defs>
              <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g id="back" transform="translate(-308 -28)">
              <g id="Group_12962" data-name="Group 12962">
                <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
                  <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                      <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                    </g>
                  </g>
                </g>
                <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
              </g>
            </g>
          </svg>

        </button>
      </div>
      <div class="comment_wrapper overflow-y-scroll scrollbar-hide" style="height: 90vh">
        <p class="header_comment">در خواست های من</p>
        <div class="comment" v-for="(item ,i) in list" :key="i">
          <img :src="baseUrl + profile" alt="">
          <div class="comment__detail">
            <p class="my-4 text-white" >{{item.data.description}}</p>
            <div class="flex justify-between items-center ">
              <p>{{item.created_at}}</p>
              <div class="flex">
                <span class="text-white">
                     <svg class="inline-block ml-1" enable-background="new 0 0 24 24" height="18.682" id="Layer_1" version="1.1" viewBox="0 0 24 24" width="16.409" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path clip-rule="evenodd" d="M12,4C4.063,4-0.012,12-0.012,12S3.063,20,12,20    c8.093,0,12.011-7.969,12.011-7.969S20.062,4,12,4z M12.018,17c-2.902,0-5-2.188-5-5c0-2.813,2.098-5,5-5c2.902,0,5,2.187,5,5    C17.018,14.812,14.92,17,12.018,17z M12.018,9c-1.658,0.003-3,1.393-3,3c0,1.606,1.342,3,3,3c1.658,0,3-1.395,3-3    C15.018,10.392,13.676,8.997,12.018,9z" fill-rule="evenodd" fill="#ff7400"/></g></g></svg>
                {{item.real_estate_count}}
              </span>
                <span v-if="item.status === 'waiting'" class="read-more">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.409" height="15.682" viewBox="0 0 9.409 15.682">
                <path id="ic_hourglass_empty_24px" d="M6,2V6.7h.008L6,6.712,9.136,9.841,6,12.977l.008.008H6v4.7h9.409v-4.7H15.4l.008-.008L12.273,9.841l3.136-3.129L15.4,6.7h.008V2Zm7.841,11.37v2.744H7.568V13.37L10.7,10.233ZM10.7,9.449,7.568,6.313V3.568h6.273V6.313Z" transform="translate(-6 -2)" fill="#ff7400"/>
              </svg>
              </span>
                <router-link v-if="item.status === 'has-accepted'" :to="{name : 'NotificationsDetailCustomer' ,params : {id : item.id}}" class="read-more">
                  <svg class="inline-block" xmlns="http://www.w3.org/2000/svg" width="12.445" height="12.445" viewBox="0 0 12.445 12.445">
                    <path id="ic_comment_24px" d="M14.438,3.244A1.243,1.243,0,0,0,13.2,2H3.244A1.248,1.248,0,0,0,2,3.244v7.467a1.248,1.248,0,0,0,1.244,1.244h8.711l2.489,2.489ZM11.956,9.467H4.489V8.222h7.467Zm0-1.867H4.489V6.356h7.467Zm0-1.867H4.489V4.489h7.467Z" transform="translate(-2 -2)" fill="#ff7400"/>
                  </svg>
                  <h6 class="inline-block text-white mr-2">{{item.real_estates_accepeted_count}}</h6>
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <infinite v-if="page === 2" @infinite="infiniteHandler">
      <div  class="text-white text-center mt-16" slot="no-results">رویدادی وجود ندارد</div>
      <div class="text-white text-center mt-16" slot="no-more"></div>

    </infinite>

  </div>
</template>

<script>
import axios from "../../plugins/axios";
import infinite from "vue-infinite-loading";
export default {
  name: "NotificationsRealEstate",
  components :{
    infinite
  },
  data(){
    return{
      list : [],
      count : 0,
      page : 1,
      profile : '',
      baseUrl : this.$store.state.general.baseURL

    }
  },
  methods :{
    infiniteHandler($state){
      if (this.page === 2){
        axios.post('/ad-requests/list?page='+this.page,{
          estate_ad_send_id: this.$route.params.id,
          status: [
            "accepted",
          ]
        }).then(({data})=>{
          if (data.data.data.length) {
            this.page += 1;
            this.list.push(...data.data.data);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
      }
    }
  },
  mounted() {
    axios.post('/ad-requests/list', {
      params: {
        page: this.page
      }
    }).then(({data}) => {
      this.page = 2
      this.list = data.data.data;
    })
  },
  created() {

    let user = JSON.parse(localStorage.getItem('user'))
    this.profile = user.profile
    axios.post('/notifications/seen')
  }
}
</script>

<style scoped>
.read-more{
  background: #644B92;
  border-radius: 0.8rem;
  font-size: 11px;
  text-align: center;
  padding: 0.5rem 0.5rem;
  border: 1px solid #FF7400;

}

</style>